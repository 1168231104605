<template>
<div>
  <v-card>
    <v-card-title>
      <span class="text-h6 pa-2">STEAM</span>
      <span class="pa-2">
            <v-btn
                color="green"
                dark
                @click="Box.New = true"
            >
              Thêm
            </v-btn>
          </span>
    </v-card-title>
    <v-card-text>
      <Datatables
          :headers="headers"
          :items="itemsWithIndex"
          :group="false"
          :loading="isLoading"
      >
        <template #tools>
        </template>
        <template #[`item.acc`]="{ item }">
          <p><v-text-field :value="item.acc"  label="Account" /></p>
          <p><v-text-field :value="item.LinkProfile"  label="Profile" v-if="item.LinkProfile"/></p>
        </template>
        <template #[`item.pass`]="{ item }">
          <p><v-text-field :value="item.pass"  label="Password" /></p>
          <p><v-text-field :value="item.LinkTrade"  label="Link Offer" v-if="item.LinkTrade"/></p>
        </template>
        <template #[`item.BackupCode`]="{ item }">
          <p><v-text-field :value="item.BackupCode"  label="Backup Code" /></p>
          <p><v-text-field :value="item.auth_code"  label="Auth Code" v-if="item.auth_code"/></p>
        </template>
        <template #[`item.Notes`]="{ item }">
          <v-textarea
              v-model="itemsWithIndex[mID(item._id)].Notes"
              label="Ghi chú"
              @change="doUpdate(item)"
          />
          <br />
          <v-text-field
              v-model="itemsWithIndex[mID(item._id)].API"
              label="API"
              @change="doUpdateAPI(item)"
          />
        </template>
      </Datatables>

    </v-card-text>
  </v-card>
  <v-dialog
      v-model="Box.New"
      max-width="900px"
      persistent
  >
    <Header
        title="Tài khoản mới"
        :close.sync="Box.New"
    >
      <v-form v-model="Valid.New">
        <v-card-title>
          Thêm tài khoản
        </v-card-title>
        <v-card-text>
          <p class="mx-2">
            <v-text-field
              v-model="NewData.acc"
              label="Tài khoản"
              :rules="[required('Tài khoản')]"
            />
          </p>
          <p class="mx-2">
            <v-text-field
                v-model="NewData.pass"
                label="Mật khẩu"
                :rules="[required('Mật khẩu')]"
            />
          </p>
          <p class="mx-2">
            <v-text-field
                v-model="NewData.mail"
                label="Email"
                :rules="[required('Email')]"
            />
          </p>
        </v-card-text>
        <v-card-actions>
          <input type="file" id="load_file" class="display:none" @change="doImportedFile">
          <v-btn
              class="mx-2"
              color="green"
              :disabled="!Valid.New"
              @click="doCreate"
          >
            Thêm
          </v-btn>
          <v-btn
              class="mx-2"
              color="blue"
              @click="doImportFile"
          >
            Import từ maFile
          </v-btn>
        </v-card-actions>
      </v-form>
      <loading :status="Loader.New" />
    </Header>
  </v-dialog>
  <v-dialog
      v-model="Box.Confirmation"
      max-width="900px"
      persistent
  >
    <Header
        title="Danh sách confirmation"
        :close.sync="Box.Confirmation"
    >
      <v-form v-model="Valid.Confirmation">
        <v-card-title>
          XÁC NHẬN GIAO DỊCH
        </v-card-title>
        <v-card-text>
          <Confirmations :items="trades" :action="doActionConfirm" />
        </v-card-text>
      </v-form>
      <loading :status="Loader.Confirmation" />
    </Header>
  </v-dialog>
  <v-dialog
      v-model="Box.Items"
      max-width="900px"
      persistent
  >
    <Header
        title="DANH SÁCH ITEM"
        :close.sync="Box.Items"
    >
        <v-card-title>
          DANH SÁCH ITEM
        </v-card-title>
        <v-card-text>
          <Items :items="ListItems" :trade_items.sync="trade_items" />
        </v-card-text>
        <v-card-actions v-if="trade_items.length > 0">
          <v-row>
            <v-col
              cols="8"
            >
              <v-text-field
                v-model="linkTrade"
                label="Link Offer"
                @change="doSaveLinkOffer"
              />
            </v-col>
            <v-col
              cols="4"
            >
              <v-btn
                color="green"
                :disabled="!linkTrade"
                @click="doSendOffer"
                :loading="Loader.Items"
              >
                Send Offer
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      <loading :status="Loader.Items" />
    </Header>
  </v-dialog>
</div>
</template>

<script>
import validations from '@/helpers/validations.js'
import {headers} from '@/config/headers/steam.js';
import Datatables from '@/components/base/datatables.vue';
import SteamHelper from '@/modules/steam.js'
import Controller from '@/config/controllers/steam.js'
import Header from '@/components/base/pagehead.vue'
import loading from '@/components/base/loading.vue'
import Confirmations from '@/components/steam/confirm.vue'
import Items from '@/components/steam/items.vue'
const {ThongBao, getStore, setStore} = require("../helpers");
export default {
  name: "Steam",
  components:{
    Datatables,
    Header,
    loading,
    Confirmations,
    Items,
  },
  data(){
    return {
      headers,
      linkTrade: null,
      isLoading: false,
      items: [],
      authCode: [],
      autoGet: undefined,
      indexKey: [],
      trades: [],
      trade_items: [],
      confirmAccount: null,
      Box:{
        New: false,
        Confirmation: false,
        Items: false,
      },
      Valid:{
        New: false,
        Confirmation: false,
      },
      Loader:{
        New: false,
        Confirmation: false,
        Items: false,
      },
      NewData:{

      },
      ListItems: {
        "csgo": [],
        "d2": []
      },
      CurrentSteamID: undefined,
    }
  },
  computed: {
    itemsWithIndex(){
      this.indexKey = [];
      return this.items.map((item,index)=>{
        this.indexKey.push(item._id);
        return {
          ...item,
          index: index + 1,
          controller:this.controller(item)
        }
      })
    }
  },
  methods:{
    ...validations,
    controller:Controller.Steam,
    mID(id){
      return this.indexKey.indexOf(id);
    },
    doSaveLinkOffer(){
      setStore('steam_offer',this.linkTrade);
    },
    doSendOffer(){
      const request = {
        offer: this.linkTrade,
        assets: this.trade_items,
      }
      this.Loader.Items = true;
      SteamHelper.SendOffer(this.CurrentSteamID,request).then(json=>{
        if(json.success){
          this.Box.Items = false;
        }
      }).finally(()=>{
        this.Loader.Items = false;
      });
    },
    doImportedFile(e){
      const input = e.target;
      if (input.files && input.files[0]) {
        const myFile = input.files[0];
        const reader = new FileReader();
        const vm = this;
        reader.addEventListener('load', function (e) {
          try {
            const json = JSON.parse(e.target.result);
            if(json['shared_secret'] && json['revocation_code'] && json['account_name'] && json['identity_secret'] && json['device_id']){
              vm.$set(vm.NewData,'acc',json['account_name']);
              vm.$set(vm.NewData,'BackupCode',json['revocation_code']);
              vm.$set(vm.NewData,'identity_secret',json['identity_secret']);
              vm.$set(vm.NewData,'shared_secret',json['shared_secret']);
              vm.$set(vm.NewData,'LinkProfile',json['Session']['SteamID']);
              vm.$set(vm.NewData,'device_id',json['device_id']);
            }else{
              ThongBao.Error('Tập tin này không phải maFile');
            }
          }catch(e){
            console.error(e);
            ThongBao.Error('Tập tin này không phải maFile');
          }

        });

        reader.readAsBinaryString(myFile);
      }
    },
    doImportFile(){
      document.getElementById('load_file').click();
    },
    doCreate(){
      this.Loader.New = true;
      SteamHelper.Create(this.NewData).then(resp=> {
        const {success} = resp;
        if(success){
          this.NewData = {};
          this.Box.New = false;
          this.doGet();
        }
      }).finally(()=>{
        this.Loader.New = false;
      });
    },
    doUpdateAPI(item){
      const {_id, API} = item;
      SteamHelper.Update(_id,{API});
    },
    doUpdate(item){
      const {_id, Notes} = item;
      SteamHelper.Update(_id,{Notes});
    },
    doGet(){
      SteamHelper.Get().then(json=>{
        const {data} = json;
        if(data){
          this.items = data;
        }
      }).finally(()=>{

      })
    },
    doOpenListConfirm(steam){
      this.trades = [];
      this.Loader.Confirmation = true;
      this.Box.Confirmation = true;
      SteamHelper.GetTradeConfirmations(steam._id).then(json=>{
        const {data} = json;
        if(data && data.length > 0){
          this.trades = data;
          this.confirmAccount = steam._id;
        }else{
          ThongBao.Error("KHÔNG CÓ GIAO DỊCH CẦN XÁC NHẬN !!!");
          this.Box.Confirmation = false;
        }
      }).finally(()=>{
        this.Loader.Confirmation = false;
      })
    },
    doLoginSteam(steam){
      this.isLoading = true;
      SteamHelper.LoginSteam(steam._id).finally(()=>{
        this.isLoading = false;
      })
    },
    doOpenListItems(steam){
      this.Loader.Items = true;
      this.Box.Items = true;
      this.CurrentSteamID = steam._id;
      SteamHelper.ListItems(steam._id).then(json=> {
        if(json.data) this.ListItems = json.data;
      }).finally(()=>{
        this.Loader.Items = false;
      });
    },
    doActionConfirm(action,data){
      this.Loader.Confirmation = true;
      SteamHelper.TradeConfirmationAction({
        action,
        id:this.confirmAccount,
        data,
      }).then((data)=>{
        if(data.success) {
          this.Box.Confirmation = false;
          this.trades = [];
          this.confirmAccount = null;
        }
      }).finally(()=>{
        this.Loader.Confirmation = false;
      })
    }
  },
  mounted(){
    this.doGet();
    this.autoGet = setInterval(this.doGet,10000);
    this.linkTrade = getStore('steam_offer') || null;
  },
  beforeDestroy(){
    if(this.autoGet){
      clearInterval(this.autoGet);
      this.autoGet = undefined;
    }
  }
}
</script>

<style scoped>
#load_file{
  display: none;
}
</style>