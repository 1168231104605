<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-img
            src="https://cdn.akamai.steamstatic.com/steamcommunity/public/images/apps/730/69f7ebe2735c366c65c0b33dae00e12dc40edbe4.jpg"
            lazy-src="https://cdn.akamai.steamstatic.com/steamcommunity/public/images/apps/730/69f7ebe2735c366c65c0b33dae00e12dc40edbe4.jpg"
            max-height="32"
            max-width="32"
            class="mx-2"
        />
        <span class="mx-2">CSGO</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <template v-if="items.csgo.length >0">
          <v-card flat>
            <v-card-title>
              <v-btn
                  class="mx-2"
                  text
                  small
                  @click="doSelect('csgo',true)"
              >
                Chọn hết
              </v-btn>
              <v-btn
                  class="mx-2"
                  text
                  small
                  @click="doSelect('csgo',false)"
              >
                Bỏ chọn
              </v-btn>
            </v-card-title>
          </v-card>
          <v-row>
            <v-col
                v-for="(item, index) in items.csgo"
                :key="index"
                cols="auto"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-card flat>
                      <v-checkbox
                        :value="trades.indexOf(item.id) > -1 ? true:false"
                        :disabled="item.tradable !== 1"
                        @change="doUpdateTrade(item)"
                      />
                      <v-img
                          class="ma-2"
                          :src='`https://community.akamai.steamstatic.com/economy/image/${item.icon_url}`'
                          :lazy-src='`https://community.akamai.steamstatic.com/economy/image/${item.icon_url}`'
                          max-height="96"
                          max-width="96"
                          v-bind="attrs"
                          v-on="on"
                      />
                  </v-card>
                </template>
                <span class="green--text text-body2" v-if="item.tradable === 1">
                    Trade được rồi
                </span>
                <span class="red--text text-body2" v-else>
                    <template v-if="item.cache_expiration">
                      Đang Khóa - {{ThoiGian(item.cache_expiration)}} - {{TimeAgo(item.cache_expiration)}}
                    </template>
                    <template v-else>
                      Không thể Trade
                    </template>
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <span class="orange--text text-h5">Không có Item</span>
        </template>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-img
            src="https://cdn.akamai.steamstatic.com/steamcommunity/public/images/apps/570/0bbb630d63262dd66d2fdd0f7d37e8661a410075.jpg"
            lazy-src="https://cdn.akamai.steamstatic.com/steamcommunity/public/images/apps/570/0bbb630d63262dd66d2fdd0f7d37e8661a410075.jpg"
            max-height="32"
            max-width="32"
            class="mx-2"
        />
        <span class="mx-2">DOTA 2</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <template v-if="items.d2.length >0">
          <v-card flat>
            <v-card-title>
              <v-btn
                  class="mx-2"
                  text
                  small
                  @click="doSelect('d2',true)"
              >
                Chọn hết
              </v-btn>
              <v-btn
                  class="mx-2"
                  text
                  small
                  @click="doSelect('d2',false)"
              >
                Bỏ chọn
              </v-btn>
            </v-card-title>
          </v-card>
          <v-row>
            <v-col
                v-for="(item, index) in items.d2"
                :key="index"
                cols="auto"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                      class="ma-2"
                      :src='`https://community.akamai.steamstatic.com/economy/image/${item.icon_url}`'
                      :lazy-src='`https://community.akamai.steamstatic.com/economy/image/${item.icon_url}`'
                      max-height="96"
                      max-width="96"
                      v-bind="attrs"
                      v-on="on"
                  />
                </template>
                <span class="green--text text-body2" v-if="item.tradable === 1">
                    Trade được rồi
                </span>
                <span class="red--text text-body2" v-else>
                    <template v-if="item.cache_expiration">
                      Đang Khóa - {{ThoiGian(item.cache_expiration)}} - {{TimeAgo(item.cache_expiration)}}
                    </template>
                    <template v-else>
                      Không thể Trade
                    </template>
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <span class="orange--text text-h5">Không có Item</span>
        </template>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import {ThoiGian,TimeAgo} from '@/helpers/strings.js';
export default {
  data(){
    return {
      trades: [],
      trade_assets: [],
    }
  },
  props: {
    items:{
      type: Object,
      required: true,
    },
    trade_items:{
      type: Array,
      required: true,
    },
  },
  computed: {
    items_grouped(){
      let data =  []
      data.concat(this.items.csgo).concat(this.items.d2);
      return data.concat(this.items.csgo).concat(this.items.d2);
    }
  },
  methods: {
    ThoiGian,
    TimeAgo,
    doSearchItemByIndex(id){
      let data = undefined;
      this.items_grouped.forEach((item,index)=>{
        if (item['id'] === id) data = item;
      });
      return data;
    },
    doEmitTradeData(){
      let data = [];
      this.trades.map(item=>{
        const item_data = this.doSearchItemByIndex(item);
        if(item_data) {
          data.push({
            "appid":parseInt(item_data['appid']),
            "contextid":"2",
            "amount":parseInt(item_data['amount']),
            "assetid":item_data['id']
          });
        }
      });
      this.$emit("update:trade_items", data);
    },
    doSelect(db,status){
      const data = this.items[db];
      if(status === true){
        data.forEach(item=>{
          if(item['tradable'] === 1){
            this.trades.push(item['id']);
          }
        });
      }else{
        data.forEach(item=>{
          const id = this.trades.indexOf(item['id']);
          if(id > -1) this.trades.splice(id,1);
        });
      }
      this.doEmitTradeData();
    },
    doUpdateTrade(item){
      if(this.trades.indexOf(item['id']) > -1){
        const id = this.trades.indexOf(item['id'])
        this.trades.splice(id,1);
      }else{
        this.trades.push(item['id']);
      }
      this.doEmitTradeData();
    },
  },
  mounted(){
  }
}
</script>

<style scoped>

</style>