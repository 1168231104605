import { ResponseData, ResponseError, axiosInstance, Request } from '@/helpers'
const moduleName = 'steam'
export const Get = () =>{
	const req = Request();
	return axiosInstance.get(`/${moduleName}`,req).then(ResponseData).catch(ResponseError)
}
export const Update = (id,data) =>{
	const req = Request();
	return axiosInstance.post(`/${moduleName}/update/${id}`,data,req).then(ResponseData).catch(ResponseError)
}
export const Create = (data) =>{
	const req = Request();
	return axiosInstance.post(`/${moduleName}/create`,data,req).then(ResponseData).catch(ResponseError)
}
export const GetTradeConfirmations = (id) =>{
	const req = Request();
	return axiosInstance.get(`/${moduleName}/confirms/${id}`,req).then(ResponseData).catch(ResponseError)
}
export const TradeConfirmationAction = ({action,id,data}) =>{
	const req = Request();
	return axiosInstance.post(`/${moduleName}/confirm/${id}/${action}`,data,req).then(ResponseData).catch(ResponseError)
}
export const LoginSteam = (id) =>{
	const req = Request();
	return axiosInstance.get(`/${moduleName}/steam_login/${id}`,req).then(ResponseData).catch(ResponseError)
}
export const ListItems = (id) =>{
	const req = Request();
	return axiosInstance.get(`/${moduleName}/items/${id}`,req).then(ResponseData).catch(ResponseError)
}
export const SendOffer = (id,request) =>{
	const req = Request();
	return axiosInstance.post(`/${moduleName}/offer/${id}`,request,req).then(ResponseData).catch(ResponseError)
}
export default {
	Get,
	Update,
	Create,
	GetTradeConfirmations,
	TradeConfirmationAction,
	LoginSteam,
	ListItems,
	SendOffer,
}